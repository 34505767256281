<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="lg"
      hide-footer
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="modal-title">เลือกวงเงินเครดิต</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
              sticky-header
            >
              <template v-slot:cell(select)="data">
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hover"
                  @click="selectRow(data)"
                  :disabled="isBusy || data.item.creditAmount < 1 "
                  >
                  เลือก
                </b-button>
              </template>
              <template v-slot:cell(name)="data">
                {{ data.index + 1 }} {{ data.item.name }}
                <font-awesome-icon
                  v-if="data.item.isCradit"
                  icon="check-circle"
                  class="is-credit"
                />
              </template>
              <template v-slot:cell(creditAmount)="data">
                <div>
                  {{ data.item.creditAmount | numeral("0,0.00") }}
                </div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div>
                  {{
                    $moment(data.item.created_time)
                      .add(543, "years")
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:cell(expired_date)="data">
                <div>
                  {{
                    $moment(data.item.expired_date)
                      .add(543, "years")
                      .format($formatDateNewFull)
                  }}
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  components: {
    OtherLoading,
    InputText,
  },
  props: {
    spaCreditList: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      user: null,
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "name",
          label: "ชื่อวงเงินเครดิต",
          tdClass: "text-left",
        },
        {
          key: "creditAmount",
          label: "วงเงินเครดิต",
        },
        {
          key: "created_time",
          label: "วันที่ซื้อ",
        },
        {
          key: "expired_date",
          label: "วันหมดอายุ",
        },
      ],
      now: null,
      items: [],
      isBusy: false,
      rows: 0,
      selectedList: [],
      rowProduct: false,
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  created: async function () {},
  methods: {
    getList: async function () {
      this.isBusy = true;

      var body = {
        user_guid: this.user.user_guid,
        isHistory: 0,
      };
      this.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/spa/get_user_credit`, body)
        .then((data) => {
          if (data.result == 1) {
            this.items = data.data;
            this.isLoading = false;
          }
        });
      this.isBusy = false;
    },
    async show(user, rowProduct) {
      this.user = user;
      this.rowProduct = rowProduct;
      this.items = this.spaCreditList;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async selectRow(data) {
      this.selectedList.push(data.item.id);
      await this.addSelect(data);
    },
    addSelect(data) {
      this.$emit("addSelect", data, this.rowProduct);
      this.hide();
    },
    clearSelectList() {
      this.selectedList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
::v-deep .is-credit {
  color: #d31145 !important;
}
</style>
