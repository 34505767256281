<template>
  <div>
    <b-modal
      centered
      v-model="showModal"
      ref="modal"
      size="xl"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">ยืนยันการใช้งานเครดิต</div>  </template>
      <div>
        <div class="h-100">
          <b-row class="px-2">
            <b-col>
              <b-row>
                <b-col
                  ><InputText
                    v-model="form.saleId"
                    textFloat="รหัสพนักงานขาย "
                    className="mb-3"
                    isRequired
                    :isValidate="$v.form.saleId.$error"
                    :v="$v.form.saleId"
                /></b-col>
                <b-col>
                  <InputTextMask
                    textFloat="วันที่ใช้ "
                    placeholder="dd/mm/yyyy hh:mm"
                    :value="form.usageTime"
                    mask="##/##/#### ##:##"
                    masked
                    @onDataChange="changeTime"
                    className="mb-3"
                    isRequired
                    :isValidate="$v.form.usageTime.$error"
                    :v="$v.form.usageTime"
                    :disabled="!(allowTimeChangeService == 'true' && isFullPos == 0)"
                  />
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                class="table-main"
                :no-border-collapse="false"
                sticky-header
              >
                <template v-slot:cell(barcode)="data">
                  <b-button variant="product-name" @click="data.toggleDetails">
                    <font-awesome-icon
                      v-if="data.detailsShowing"
                      icon="chevron-down"
                      class="when-open"
                    />
                    <font-awesome-icon
                      icon="chevron-right"
                      class="when-closed"
                      v-else
                    />
                    {{ data.item.barcode }}
                  </b-button>
                </template>
                <template #row-details="data">
                  <b-card body-class="px-0 py-2" class="mb-0">
                    <div class="px-3">
                      <b-row>
                        <b-col class="text-center"><b>ชื่อวงเงิน</b></b-col>
                        <b-col cols="2" class="text-center"
                          ><b>วงเงินเครดิต</b></b-col
                        >
                        <b-col cols="2" class="text-center"
                          ><b>ยอดที่ใช้</b></b-col
                        >
                        <b-col cols="2" class="text-center">
                          <b-button
                            v-if="data.item.price_balance > 0"
                            variant="link"
                            @click="$refs.modalSelectCredit.show(user, data)"
                          >
                            เลือกวงเงินเครดิต
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row
                        class="my-3"
                        v-if="data.item.list_credit.length == 0"
                      >
                        <b-col>
                          <p>กรุณาเลือกวงเงิน</p>
                        </b-col>
                      </b-row>
                      <b-row
                        v-else
                        v-for="(credit, i) in data.item.list_credit"
                        :key="i"
                        class="mt-2"
                      >
                        <b-col class=""
                          >{{ credit.index + 1 }} {{ credit.name }} ({{
                            credit.plant_id
                          }})</b-col
                        >
                        <b-col cols="2" class="text-center">{{
                          credit.remaining_balance | numeral("0,0.00")
                        }}</b-col>
                        <b-col cols="2" class="text-center">
                          {{ credit.use_price | numeral("0,0.00") }}
                        </b-col>
                        <b-col cols="2" class="text-center">
                          <b-button
                            variant="link"
                            class="delete"
                            @click="deleteSelectCredit(data)"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </template>
                <template v-slot:cell(name)="data">
                  {{ data.item.name }}
                  <font-awesome-icon
                    v-if="data.item.price_balance == 0"
                    icon="check-circle"
                    class="is-credit"
                  />
                </template>
                <template v-slot:cell(price)="data">
                  <div>
                    {{ data.item.price | numeral("0,0.00") }}
                  </div>
                </template>
                <template v-slot:cell(duration)="data">
                  <div
                    class="w-100 d-flex align-items-center justify-content-center"
                  >
                    <b-button
                      variant="quantity"
                      @click="handleQuantity(data, 'minus')"
                    >
                      <font-awesome-icon
                        icon="minus-circle"
                        title="down-btn"
                        class="pointer"
                      />
                    </b-button>
                    <p class="mx-2">{{ data.item.quantity }}</p>
                    <b-button
                      variant="quantity"
                      @click="handleQuantity(data, 'plus')"
                    >
                      <font-awesome-icon
                        icon="plus-circle"
                        title="up-btn"
                        class="pointer"
                      />
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant="link" @click="deleteProduct(data)">
                    ลบ
                  </b-button>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-row class="panel-total">
                <b-col>
                  <p class="title">Total</p>
                  <p>{{ total_quantity | numeral("0,0") }} รายการ</p>
                </b-col>
                <b-col class="text-right">
                  <p class="title">ราคารวม (บาท)</p>
                  <p>
                    {{ total_price | numeral("0,0.00") }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-25 ml-1"
            @click="confirm"
            :disabled="isLoading"
          >
            ตกลง
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalSelectCredit
      ref="modalSelectCredit"
      :spaCreditList="spaCreditList"
      @addSelect="addSelect"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import InputText from "@/components/inputs/InputText";
import InputTextMask from "@/components/inputs/InputTextMask";
import ModalSelectCredit from "./ModalSelectCredit";

import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    HeaderPanel,
    InputText,
    InputTextMask,
    ModalSelectCredit,
  },
  watch: {
    items: {
      deep: true,
      handler: function (newValue) {
        let countQty = 0;
        let totalPrice = 0;
        newValue.forEach((element) => {
          countQty += element.quantity;
          totalPrice += parseInt(element.quantity) * parseInt(element.price);
        });
        this.total_quantity = countQty;
        this.total_price = totalPrice;
      },
    },
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      userGuid: "getUserGuid",
      allowTimeChangeService: "getAllowTimeChangeService",
      isFullPos: "getIsFullPos",
    }),
  },
  validations() {
    return {
      form: {
        saleId: {
          required,
        },
        usageTime: {
          required,
          maxValueToday: (value) => {
            let dateTime = this.$moment(value, 'DD/MM/YYYY HH:mm').add(-543, 'years').unix();
            let now = this.$moment().unix();
            return dateTime <= now;
          },
        },
        listSingleCourse: {
          required,
          $each: {
            product_id: {
              minValue: minValue(1),
            },
            price_balance: {
              priceBalance(value) {
                return parseInt(value) == 0;
              },
            },
            quantity: {
              minValue: minValue(1),
            },
            list_credit: {
              required,
              $each: {
                use_credit: {
                  minValue: minValue(1),
                }
              }
            }
          }
        }
      },
    };
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      fields: [
        {
          key: "barcode",
          label: "รหัสสินค้า Barcode",
          tdClass: "text-left",
        },
        {
          key: "name",
          label: "ชื่อสินค้า Name",
        },
        {
          key: "price",
          label: "ราคาสินค้า Price",
        },
        {
          key: "duration",
          label: "จำนวนครั้ง Duration",
        },
        {
          key: "action",
          label: "",
        },
      ],
      form: {
        id: 1,
        saleId: "",
        usageTime: null,
        userGuid: "",
        branchId: 0,
        listSingleCourse: [],
        totalPrice: 0.0,
        totalCredit: 0.0,
        salesList: [],
      },
      filter: {
        page: 1,
        take : 10,
        search: "",
        category: "",
      },
      items: [],
      isBusy: false,
      rows: 0,
      user: false,
      credit: false,
      spaCreditList: [],
      products: [],
      total_price: 0,
      total_quantity: 0,
      saleList: []
    };
  },
  methods: {
    async show(user, credit, products) {
      this.user = user;
      this.credit = credit;
      this.products = products;
      this.salesList = [];
      this.form = {
        id: 1,
        saleId: "",
        usageTime: this.$moment().add(543, 'years').format("DD/MM/YYYY HH:mm"),
        userGuid: "",
        branchId: 0,
        listSingleCourse: [],
        totalPrice: 0.0,
        totalCredit: 0.0,
        salesList: [],
      },

      await this.getUserCredit();
      await this.getConfirmSpaSingleCourse();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    changeTime(val) {
      this.form.usageTime = val;
    },
    async getUserCredit() {
      var body = {
        user_guid: this.user.user_guid,
        isHistory: 0,
      };
      await this.axios
        .post(`${this.$baseUrl}/spa/get_user_credit`, body)
        .then(async (data) => {
          if (data.result == 1) {
            data.data = await data.data.map((item) => {
              return {
                ...item,
                creditAmount: item.remaining_balance,
              };
            });
            this.spaCreditList = data.data;
          }
        });
    },
    async getConfirmSpaSingleCourse() {
      await this.axios
        .post(`${this.$baseUrl}/spa/confirm_spa_single_course`, this.products)
        .then(async (data) => {
          if (data.result == 1) {
            data.data = await data.data.map((item) => {
              return {
                ...item,
                list_credit: [],
                price_balance: parseInt(item.price) * parseInt(item.quantity),
              };
            });
            this.items = data.data;
          }
        });
    },
    async onDataChange(val) {
      this.filter.category = val;
      this.getList();
    },
    async handleQuantity(data, type) {
      if (type == "minus") {
        if (data.item.quantity > 1) data.item.quantity -= 1;
      } else if (type == "plus") {
        data.item.quantity += 1;
      }
    },
    async checkIsSalesValid() {
      let result = false;
      try {
        this.isLoading = false;
        await this.axios
          .get(
            `${this.$baseUrl}/sales/${this.form.saleId}/branch/${this.branchId}`
          )
          .then(async (data) => {
            if (data.result == 1) {
              this.form.salesList.push(this.form.saleId);
              result = true;
            } else {
              this.warningAlert(data.message || "ไม่พบข้อมูล");
            }
          });
      } catch (error) {
        this.warningAlert(error);
      }
      return result
    },
    async confirm() {
      if (this.form.saleId) {
        let result =  await this.checkIsSalesValid();
        if(!result) return;
      }
      this.form.listSingleCourse = this.items;
      let list_single_course = await this.items.map((item) => {
        let list_credit = item.list_credit.map((credit) => {
          return {
            id: credit.id,
            use_credit: credit.use_price,
          };
        });
        return {
          price: item.price,
          product_id: item.product_id,
          quantity: item.quantity,
          list_credit: list_credit,
        };
      });
      this.$v.form.$touch();

      if (this.$v.form.listSingleCourse.$error) {
        this.warningAlert('กรุณาเลือกวงเงินเครดิต');
      }
      if (this.$v.form.$error) return;

      this.isLoading = true;
      let body = {
        user_guid: this.user.user_guid,
        branch_id: this.branchId,
        sales_id: this.form.saleId,
        usage_time: this.form.usageTime,
        list_single_course: list_single_course,
        total_price: 0.0,
        total_credit: 0.0,
        sales_list: this.form.salesList,
      };
      
      try {
        this.isLoading = false;
        await this.$store.dispatch("setMainLoading", true);
        await this.axios
          .post(`${this.$baseUrl}/spa/use_spa_credit`, body)
          .then(async (data) => {
            await this.$store.dispatch("setMainLoading", false);
            if (data.result == 1) {
              await this.successAlert(data.message);
              await this.$emit("success");
              await this.hide();
            } else {
              this.warningAlert(data.message);
            }
          });
      } catch (error) {
        await this.$store.dispatch("setMainLoading", false);
        this.warningAlert(error);
      }
    },
    async addSelect(credit, rowProduct) {
      let use_price =
        credit.item.creditAmount >= this.items[rowProduct.index].price_balance
          ? this.items[rowProduct.index].price_balance
          : credit.item.creditAmount;
      this.items[rowProduct.index].list_credit.push({
        ...credit.item,
        index: credit.index,
        use_price: use_price,
      });
      this.items[rowProduct.index].price_balance -= use_price;
      this.spaCreditList[credit.index].creditAmount -= use_price;
    },
    async deleteSelectCredit(rowProduct) {
      this.items[rowProduct.index].price_balance =
        parseInt(this.items[rowProduct.index].price) *
        parseInt(this.items[rowProduct.index].quantity);
      this.$refs.modalSelectCredit.clearSelectList();
      await this.items[rowProduct.index].list_credit.forEach((credit) => {
        this.spaCreditList[credit.index].creditAmount += credit.use_price;
      });

      this.items[rowProduct.index].list_credit = [];
    },
    async deleteProduct(rowProduct) {
      await this.items[rowProduct.index].list_credit.forEach((credit) => {
        this.spaCreditList[credit.index].creditAmount += credit.use_price;
      });
      this.items.splice(rowProduct.index, 1);
      if (this.items.length == 0) {
        this.warningAlert("ไม่มีสินค้า");
        this.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}
.btn-quantity {
  padding: 0;
  svg {
    color: #333 !important;
  }
}
.panel-total {
  font-size: 22px;
  font-family: "Kanit-Medium" !important;
  .title {
    font-size: 14px;
  }
}
::v-deep .btn-link {
  padding: 0 10px;
  color: #333;
  text-decoration: underline;
  cursor: pointer !important;
}
::v-deep .btn-link.delete {
  svg {
    color: #707070 !important;
  }
}
::v-deep .btn-product-name {
  color: #707070 !important;
  text-align: left;
  padding: 0;
  svg {
    color: #707070 !important;
    font-weight: bold !important;
  }
}
::v-deep .is-credit {
  color: #d31145 !important;
}
</style>
