<template>
  <div>
    <b-modal
      v-model="showModal"
      centered
      ref="modal"
      size="xl"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">รายละเอียดการใช้งาน</div>  </template>
      <div>
        <div class="h-100">
          <b-row class="">
            <b-col>
              <div class="product-list-container mb-3 px-3">
                <b-row class="my-3 text-center header-products">
                  <b-col>
                    <p>วันที่ | เวลา</p>
                  </b-col>
                  <b-col md="4">
                    <p>รายการ</p>
                  </b-col>
                  <b-col>
                    <p>จำนวนครั้งที่ใช้</p>
                  </b-col>
                  <b-col>
                    <p>จำนวนทั้งหมด</p>
                  </b-col>
                  <b-col>
                    <p>สาขาที่เข้ารับบริการ</p>
                  </b-col>
                  <b-col cols="1"> </b-col>
                </b-row>
                <div class="item-products" v-if="items.length > 0">
                  <b-row
                    v-for="(item, index) in items"
                    :key="index"
                    :class="[
                      'border-top pt-1 ',
                      { 'spa-disable': item.status == 1 },
                    ]"
                  >
                    <b-col :class="['price text-center']">
                      {{
                        $moment(item.created_time)
                          .add(543, "years")
                          .format($formatDateNewFull)
                      }}
                    </b-col>
                    <b-col md="4" :class="['price text-center']">
                      {{ item.order_name }}
                      <p v-if="item.status == 1" class="text-danger">
                        ยกเลิก ({{ item.manager_no }})
                      </p>
                    </b-col>
                    <b-col class="price text-center">
                      {{ item.total_usage | numeral("0,0") }}
                    </b-col>
                    <b-col class="price text-center">
                      {{ item.usage_time | numeral("0,0") }}
                    </b-col>
                    <b-col class="price text-center">
                      {{ item.branch_name }}
                    </b-col>
                    <b-col cols="1" class="price text-center">
                      <b-dropdown
                        variant="link"
                        toggle-class="dropdown-icon"
                        no-caret
                        right
                        v-if="item.status !== 1"
                      >
                        <template #button-content v-if="item.is_import != 7">
                          <font-awesome-icon
                            icon="ellipsis-v"
                            class="pointer"
                          />
                        </template>
                        <b-dropdown-item
                          @click="$refs.modalCancelBillUsage.show(item)"
                          >ยกเลิกรายการ</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-25 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ปิด
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalCancelBillUsage
      ref="modalCancelBillUsage"
      :branchId="branchId"
      @success="handleCancelBillUsageSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import InputText from "@/components/inputs/InputText";
import ModalCancelBillUsage from "./ModalCancelBillUsage";

export default {
  components: {
    HeaderPanel,
    InputText,
    ModalCancelBillUsage,
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: null,
      },
      total_credit: 0,
      items: [],
      isBusy: false,
      rows: 0,
      product: false,
    };
  },
  methods: {
    async show(user, product) {
      this.user = user;
      this.product = product;
      await this.getList();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getList() {
      await this.axios
        .get(
          `${this.$baseUrl}/spa/get_single_course_history/${this.product.user_transaction_product_spa_id}`
        )
        .then((data) => {
          this.items = data.detail;
          this.rows = this.items.length;
        });
    },
    async handleCancelBillUsageSuccess() {
      this.$emit("reload");
      await this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}
.header-products {
  font-size: 14px;
  font-weight: bold;
}
.spa-disable .price {
  opacity: 0.5;
}
.spa-disable .price:first-of-type,
.spa-disable .action {
  opacity: 1;
}
.item-products {
  color: #333;
  font-size: 16px;
  border-radius: 8px;
  font-family: Kanit-Medium !important;
  height: 100%;
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
  svg {
    color: #000;
  }
  .btn-product-name {
    color: #707070;
    text-align: left;
    padding: 0;
    svg {
      color: #707070;
      font-weight: bold !important;
    }
  }
  .expire-date {
    color: var(--primary-color) !important;
    font-size: 12px;
  }
  .balance {
    color: var(--primary-color) !important;
    text-align: center;
    font-size: 16px;
  }
  .price {
    font-size: 14px;
    text-align: center;
  }
  .normal-unit-price {
    text-decoration: line-through;
  }
  .action {
    .dropdown-menu.show {
      padding: 0 !important;
      margin-bottom: 0 !important;
      min-width: 100px !important;
      width: 100px !important;
      .dropdown-item {
        padding: 5px !important;
        font-size: 12px !important;
      }
    }
  }
}
.delete-credit {
  background-color: #000 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px;
  text-align: center;
  svg {
    color: white;
    font-size: 16px;
  }
}
::v-deep .dropdown-icon {
  padding: 0 5px !important;
  svg {
    color: #333 !important;
    font-size: 14px;
  }
}
::v-deep .dropdown-menu.show {
  padding: 0 !important;
  margin-bottom: 0 !important;
  min-width: 100px !important;
  width: 100px !important;
  .dropdown-item {
    padding: 5px !important;
    font-size: 12px !important;
  }
}
.bg-secondary {
  background-color: var(--secondary-color) !important;
}
</style>
