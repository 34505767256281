<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="sm"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">ยืนยันการยกเลิก</div>
      </template>
      <div class="d-flex justify-content-center">
        <div class="d-block w-100">
          <p class="mb-3 text-center font-weight-bold">
            หากคุณต้องการยกเลิกรายการ {{ credit.invoice_no }} กรุณากรอก Maneger
            ID เพื่อยืนยันการยกเลิก
          </p>
          <InputText
            v-model="form.managerId"
            textFloat="Manager ID:"
            className="mb-2"
            isRequired
            :isValidate="$v.form.managerId.$error"
            :v="$v.form.managerId"
          />
          <InputTextArea
            textFloat="เหตุผล"
            name="note"
            rows="6"
            v-model="form.note"
            className="mb-0"
          />
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="cancelBillCreditUsage"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import InputTextArea from "@/components/inputs/InputTextArea";

export default {
  components: {
    InputText,
    InputTextArea,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        managerId: "",
        note: "",
      },
      credit: {
        id: 0,
        invoice_no: "",
      },
    };
  },
  validations() {
    return {
      form: {
        managerId: {
          required,
        },
      },
    };
  },
  methods: {
    async show(credit) {
      this.credit = { ...credit };
      this.form = {
        managerId: "",
        note: "",
      }
      this.$v.form.$reset();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async cancelBillCreditUsage() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      await this.$store.dispatch("setMainLoading", true);
      let body = {
        transaction_id: this.credit.id,
        manager_no: this.form.managerId,
        reason: this.form.note,
      };
      await this.axios
        .post(`${this.$baseUrl}/SpaVoid/cancel_bill_credit_usage`, body)
        .then((data) => {
          this.isLoading = false;
          this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert(data.message);
            this.$emit("success");
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.panel-display-count {
  padding-top: 10px;
  p.label {
    color: #333 !important;
    font-weight: bold !important;
  }
  p {
    color: #999 !important;
    font-size: 14px !important;
  }
}
</style>
