<template>
  <div>
    <b-row class="px-3 my-3 text-center header-products">
      <b-col md="3">
        <p>รหัสสินค้า / คำอธิบาย / โปรโมชัน</p>
      </b-col>
      <b-col>
        <p>สาขาที่ซื้อ</p>
      </b-col>
      <b-col>
        <p>วันที่ซื้อ</p>
      </b-col>
      <b-col>
        <p>จำนวนทั้งหมด</p>
      </b-col>
      <b-col>
        <p>ใช้ไป</p>
      </b-col>
      <b-col>
        <p>คงเหลือ</p>
      </b-col>
      <b-col class="px-0"> </b-col>
    </b-row>
    <div class="product-list-container">
      <div class="d-none">{{ (total = 0) }}</div>
      <div :class="['item-products']" v-if="creditBalance">
        <div class="d-none">{{ (total += 1) }}</div>
        <b-row
          :class="[
            'product-panel align-items-center mx-0 mb-2',
            {
              'spa-disable': !creditBalance.isActive,
            },
          ]"
        >
          <b-col md="3" class="pointer py-3">
            <p>วงเงินเครดิต</p>
            <p class="expire-date">
              วันหมดอายุ:
              {{
                $moment(creditBalance.expire_date_time)
                  .add(543, "years")
                  .format($formatDateNew)
              }}
            </p>
          </b-col>
          <b-col class="price">
            <p></p>
          </b-col>
          <b-col class="price">
            <p>-</p>
          </b-col>
          <b-col class="price">
            <p>
              {{ creditBalance.total_credit_balance | numeral("0,0.00") }}
            </p>
          </b-col>
          <b-col class="price">
            <p>
              {{ creditBalance.total_used | numeral("0,0.00") }}
            </p>
          </b-col>
          <b-col class="balance">
            <p>
              {{ creditBalance.remaining_balance | numeral("0,0.00") }}
            </p>
          </b-col>
          <b-col class="action d-flex justify-content-center px-0">
            <b-button
              variant="primary-color"
              :disabled="!creditBalance.isActive"
              @click="handleModalSpaProductList(creditBalance)"
            >
              ใช้สิทธิ์
            </b-button>
            <b-dropdown variant="link" no-caret right>
              <template #button-content>
                <font-awesome-icon icon="ellipsis-v" class="pointer" />
              </template>
              <b-dropdown-item @click="showCreditUsedHistory(creditBalance)"
                >ดูประวัติการใช้งาน</b-dropdown-item
              >
              <b-dropdown-item @click="showCreditHistory(creditBalance)"
                >แก้ไขวันหมดอายุ</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
      <div class="item-products" v-if="packageList.length > 0">
        <b-row
          v-for="(product, index) in packageList"
          :key="index"
          :class="[
            'product-panel align-items-center mx-0 mb-2',
            { 'spa-disable': !product.isActive },
          ]"
        >
          <template v-if="product.product_list.length != 0">
            <div class="d-none">{{ (total += 1) }}</div>
            <b-col md="3" class="pointer pt-3 pr-0">
              <b-button
                v-b-toggle="'collapse-' + product.product_id"
                variant="product-name"
              >
                <font-awesome-icon icon="chevron-right" class="when-closed" />
                <font-awesome-icon icon="chevron-down" class="when-open" />
                {{ product.product_name }} <br />
                {{ product.barcode }}
              </b-button>
              <p class="expire-date">
                วันหมดอายุ:
                {{
                  $moment(product.expire_date_time)
                    .add(543, "years")
                    .format($formatDateNew)
                }}
              </p>
            </b-col>
            <b-col class="price">
              <p>{{ product.branch_name }} ({{ product.plant_id }})</p>
            </b-col>
            <b-col class="price">
              <p>
                {{
                  $moment(product.created_time)
                    .add(543, "years")
                    .format($formatDateNew)
                }}
              </p>
            </b-col>
            <b-col class="price">
              <p>
                {{ product.total_qty | numeral("0,0") }}
              </p>
            </b-col>
            <b-col class="price">
              <p>{{ product.product_usage_count | numeral("0,0") }}</p>
            </b-col>
            <b-col class="balance">
              <p>
                {{ product.outstanding_qty | numeral("0,0.") }}
              </p>
            </b-col>
            <b-col class="action d-flex justify-content-center px-0">
              <b-button
                variant="primary-color"
                :disabled="!product.isActive"
                @click="handleUsage(product)"
              >
                ใช้สิทธิ์
              </b-button>
              <b-dropdown variant="link" no-caret right>
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v" class="pointer" />
                </template>
                <b-dropdown-item @click="handleShowUsageHistory(product)"
                  >ดูประวัติการใช้งาน</b-dropdown-item
                >
                <b-dropdown-item @click="editExpiredDate(product)"
                  >แก้ไขวันหมดอายุ</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="product.article_type == 'ZPACK'"
                  @click="upgradePackage(product)"
                  >อัพเกรด</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col cols="12" class="pb-3">
              <b-collapse
                :id="`collapse-${product.product_id}`"
                :class="['product-panel align-items-center mx-0']"
              >
                <b-row
                  v-for="(productSpa, index2) in product.product_list"
                  :key="index2"
                >
                  <b-col md="3" class="price text-left pr-0">
                    - {{ productSpa.name }}
                  </b-col>
                  <b-col class="price"> </b-col>
                  <b-col class="price"> </b-col>
                  <b-col class="price">
                    <p>
                      {{ productSpa.quantity | numeral("0,0") }}
                    </p>
                  </b-col>
                  <b-col class="price"
                    ><p>
                      {{ productSpa.usage_count | numeral("0,0") }}
                    </p></b-col
                  >
                  <b-col class="balance"
                    ><p>
                      {{
                        (productSpa.quantity - productSpa.usage_count)
                          | numeral("0,0")
                      }}
                    </p></b-col
                  >
                  <b-col class="px-0"> </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </template>
        </b-row>
      </div>
      <!-- <pre>{{ packageList }}</pre> -->
      <div v-if="!user.user_guid" class="p-2 wrap-no-product">
        <div class="no-product">
          ไม่มีรายการใช้งาน<br />
          กรุณาเข้ารหัสสมาชิกเพื่อทำรายการ
        </div>
      </div>
      <p v-else>ทั้งหมด {{ total }} รายการ</p>
    </div>
    <ModalSpaProductList
      ref="modalSpaProductList"
      :categoryAh2List="categoryAh2List"
      @reload="$emit('reload')"
    />
    <ModalCreditHistory ref="modalCreditHistory" />
    <ModalCreditUsedHistory
      ref="modalCreditUsedHistory"
      @reload="$emit('reload')"
    />
    <ModalEditExpiredDate
      ref="modalEditExpiredDate"
      @success="$emit('reload')"
    />
    <ModalComfirmUsageSingleCourse
      ref="modalComfirmUsageSingleCourse"
      @success="$emit('reload')"
    />
    <ModalSingleCourseUsageHistory
      ref="modalSingleCourseUsageHistory"
      @success="$emit('reload')"
    />
    <ModalPackageList ref="modalPackageList" @success="$emit('reload')" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalSpaProductList from "./ModalSpaProductList";
import ModalCreditHistory from "./ModalCreditHistory";
import ModalCreditUsedHistory from "./ModalCreditUsedHistory";
import ModalEditExpiredDate from "./../ModalEditExpiredDate";
import ModalComfirmUsageSingleCourse from "./../singleCourse/ModalComfirmUsageSingleCourse";
import ModalSingleCourseUsageHistory from "./../singleCourse/ModalSingleCourseUsageHistory";
import ModalPackageList from "./../package/ModalPackageList";

export default {
  props: {
    tabIndex: {
      type: [String, Number],
    },
    user: {
      type: Object,
    },
    creditBalance: {
      type: Object,
    },
    packageList: {
      type: Array,
      default: [],
    },
    categoryAh2List: {
      type: Array,
      default: [],
    },
  },
  components: {
    ModalSpaProductList,
    ModalCreditHistory,
    ModalCreditUsedHistory,
    ModalEditExpiredDate,
    ModalComfirmUsageSingleCourse,
    ModalSingleCourseUsageHistory,
    ModalPackageList,
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
      dateTimeFix: "getDateTimeFix",
      isFullPos: "getIsFullPos",
    }),
  },
  methods: {
    async handleModalSpaProductList(credit) {
      this.$refs.modalSpaProductList.show(this.user, credit);
    },
    async showCreditUsedHistory(credit) {
      this.$refs.modalCreditUsedHistory.show(this.user);
    },
    async showCreditHistory(credit) {
      this.$refs.modalCreditHistory.show(this.user, credit);
    },
    async editExpiredDate(credit) {
      credit.id = credit.user_transaction_product_spa_id;
      this.$refs.modalEditExpiredDate.show(this.user, credit);
    },
    async upgradePackage(product) {},
    async handleUsage(product) {
      if (product.article_type == "ZPACK") {
        this.$refs.modalPackageList.show(this.user, product);
      } else if (product.article_type == "ZSER") {
        this.$refs.modalComfirmUsageSingleCourse.show(this.user, product);
      }
    },
    async handleShowUsageHistory(product) {
      if (product.article_type == "ZPACK") {
      } else if (product.article_type == "ZSER") {
        this.$refs.modalSingleCourseUsageHistory.show(this.user, product);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-text {
  color: var(--primary-color) !important;
  font-weight: bold;
  font-size: 20px !important;

  margin-bottom: 10px;
}
::v-deep .product-list-container {
  overflow: auto;
  height: calc(100vh - 50px - 78px - 50px);
  //   height: calc(100vh - 46px - 89px - 87px - 40px);
  // position: relative;
  .wrap-no-product {
    width: 100%;
    height: 100%;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    .no-product {
      text-align: center;
      font-size: 20px;
      padding: 15px;
      background-color: #f7f7f7;
      border-radius: 5px;
      color: #dcdcdc;
      width: 450px;
    }
  }
}
::v-deep .head-total {
  .col:first-of-type {
    padding-left: 0;
  }
  .col {
    padding: 0 5px;
  }
  .col:last-of-type {
    padding-right: 0;
  }
  .bg-secondary {
    padding: 15px 0;
    background: var(--secondary-color) !important;
  }
}
.header-products {
  font-size: 14px;
  font-weight: bold;
}
.product-panel {
  background-color: rgb(244, 244, 244) !important;
}
.product-panel.active {
  background-color: var(--secondary-color) !important;
}
.product-panel.spa-disable > div {
  opacity: 0.5;
}
.product-panel.spa-disable .action {
  opacity: 1;
  svg {
    color: #707070 !important;
  }
}
::v-deep .item-products {
  color: #707070;
  font-size: 16px;
  border-radius: 8px;
  font-family: Kanit-Medium !important;
  //   height: 100%;
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
  svg {
    color: #707070 !important;
  }
  .btn-product-name {
    color: #707070;
    text-align: left;
    padding: 0;
    svg {
      color: #707070;
      font-weight: bold !important;
    }
  }
  .expire-date {
    color: var(--primary-color) !important;
    font-size: 12px;
  }
  .balance {
    color: var(--primary-color) !important;
    text-align: center;
    font-size: 16px;
  }
  .price {
    font-size: 14px;
    text-align: center;
  }
  .normal-unit-price {
    text-decoration: line-through;
  }
  .action {
    .dropdown-menu.show {
      padding: 0 !important;
      margin-bottom: 0 !important;
      min-width: 100px !important;
      width: 100px !important;
      .dropdown-item {
        padding: 5px !important;
        font-size: 12px !important;
      }
    }
  }
}
.delete-credit {
  background-color: #000 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px;
  text-align: center;
  svg {
    color: white;
    font-size: 16px;
  }
}
</style>
