<template>
  <div>
    <b-modal
      v-model="showModal"
      centered
      ref="modal"
      size="xl"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">รายละเอียดการใช้งาน</div>  </template>
      <div>
        <div class="h-100">
          <b-row class="">
            <b-col>
              <b-row no-gutters class="align-items-end">
                <b-col
                  ><InputText
                    v-model="filter.invoiceNo"
                    textFloat="ค้นหาเลข invoice"
                    name="search_product"
                    @onEnter="handleSearch"
                /></b-col>
                <b-col class="mx-3"
                  ><InputDatePickerFilter
                    textFloat="วันเริ่มต้นการใช้งาน (Start Date)"
                    name="expire"
                    v-model="filter.startDate"
                    placeholder="DD/MM/YYYY"
                /></b-col>
                <b-col
                  ><InputDatePickerFilter
                    textFloat="วันสิ้นสุดการใช้งาน (End Date)"
                    name="expire"
                    v-model="filter.endDate"
                    placeholder="DD/MM/YYYY"
                /></b-col>
                <b-col cols="1"
                  ><div class="fa-icon-custom" @click="handleSearch">
                    <font-awesome-icon icon="search" class="pointer" /></div
                ></b-col>
              </b-row>
              <div>
                <b-row class="px-3 my-3 text-center header-products">
                  <b-col md="3">
                    <p>วันที่ | เวลา</p>
                  </b-col>
                  <b-col>
                    <p>รายการ</p>
                  </b-col>
                  <b-col>
                    <p>วงเงินที่ใช้</p>
                  </b-col>
                  <b-col>
                    <p>จำนวนที่ใช้ (บาท)</p>
                  </b-col>
                  <b-col>
                    <p>คงเหลือ (บาท)</p>
                  </b-col>
                  <b-col>
                    <p>สาขาที่เข้ารับบริการ</p>
                  </b-col>
                  <b-col class="px-0"> </b-col>
                </b-row>
                <div class="product-list-container mb-3">
                  <div class="item-products" v-if="items.length > 0">
                    <b-row
                      v-for="(item, index) in items"
                      :key="index"
                      :class="['product-panel align-items-center mx-0']"
                    >
                      <b-col
                        :class="[
                          'price bg-secondary text-left py-1 mb-2',
                          {
                            'text-gray':
                              item.user_transaction_status_id != 1 ||
                              item.action_id == 8,
                          },
                        ]"
                      >
                        <p class="font-weight-bold">
                          Invoice: {{ item.invoice_no }}
                        </p>
                      </b-col>
                      <b-col
                        :class="[
                          'action bg-secondary d-flex justify-content-end mb-2',
                          {
                            'text-gray':
                              item.user_transaction_status_id != 1 ||
                              item.action_id == 8,
                          },
                        ]"
                      >
                        <b class="price my-1 font-weight-bold">
                          ราคาสุทธิของบิล:
                          {{ item.grand_total | numeral("0,0.00") }}</b
                        >
                        <b-dropdown
                          variant="link"
                          toggle-class="dropdown-icon"
                          no-caret
                          right
                          v-if="
                            item.user_transaction_status_id == 1 &&
                            item.action_id != 8
                          "
                        >
                          <template #button-content v-if="item.is_import != 7">
                            <font-awesome-icon
                              icon="ellipsis-v"
                              class="pointer"
                            />
                          </template>
                          <b-dropdown-item
                            @click="$refs.modalCreditCancelBillUsage.show(item)"
                            >ยกเลิกรายการ</b-dropdown-item
                          >
                        </b-dropdown>
                      </b-col>
                      <b-col cols="12">
                        <div :class="['product-panel align-items-center mx-0']">
                          <b-row
                            v-if="!item.credit_detail.length"
                            class="border-bottom pb-1"
                          >
                            <b-col class="price text-center">
                              ไม่พบข้อมูล
                            </b-col>
                          </b-row>
                          <b-row
                            v-for="(credit, index2) in item.credit_detail"
                            :key="index2"
                            :class="[
                              'border-bottom pb-1',
                              {
                                'text-gray':
                                  item.user_transaction_status_id != 1 ||
                                  item.action_id == 8,
                              },
                            ]"
                          >
                            <b-col :class="['price pr-0']">
                              {{
                                $moment(
                                  credit.usage_time,
                                  "MM/DD/YYYY HH:mm:ss"
                                )
                                  .add(543, "years")
                                  .format($formatDateNewFull)
                              }}
                            </b-col>
                            <b-col class="price pr-0">
                              {{ credit.product_name }}
                            </b-col>
                            <b-col class="price pr-0">
                              00 {{ credit.branch_name }}
                            </b-col>
                            <b-col class="price pr-0">
                              {{
                                credit.original_burn_income | numeral("0,0.00")
                              }}
                            </b-col>
                            <b-col class="price pr-0">
                              {{
                                credit.transaction_balance | numeral("0,0.00")
                              }}
                            </b-col>
                            <b-col class="price pr-0">
                              {{ credit.branch_name }}
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <Pagination
                  @handleChangeTake="handleChangeTake"
                  :pageOptions="pageOptions"
                  :filter="filter"
                  :rows="rows"
                  @pagination="pagination"
                  :hideTake="true"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-25 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ปิด
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalCreditCancelBillUsage
      ref="modalCreditCancelBillUsage"
      :branchId="branchId"
      @success="handleCancelBillUsageSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import InputText from "@/components/inputs/InputText";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import ModalCreditCancelBillUsage from "./ModalCreditCancelBillUsage";
import Pagination from "@/components/Pagination";

export default {
  components: {
    HeaderPanel,
    InputText,
    InputDatePickerFilter,
    ModalCreditCancelBillUsage,
    Pagination,
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: null,
      },
      filter: {
        page: 1,
        take : 10,
        userGuId: "",
        invoiceNo: "",
        startDate: null,
        endDate: null,
      },
      total_credit: 0,
      items: [],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  methods: {
    async show(user) {
      this.user = user;
      this.filter = {
        page: 1,
        take : 10,
        userGuId: "",
        invoiceNo: "",
        startDate: null,
        endDate: null,
      };
      await this.getList();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getList() {
      var body = {
        skip: (this.filter.page - 1) * this.filter.take,
        take: this.filter.take,
        invoiceNo: this.filter.invoiceNo,
        userGuId: this.user.user_guid,
        startDate:
          this.filter.startDate ||
          this.$moment("1970-01-01").format("YYYY-MM-DD"),
        endDate: this.filter.endDate || this.$moment().format("YYYY-MM-DD"),
      };
      await this.axios
        .post(`${this.$baseUrl}/Spa/get_usage_transaction_credit`, body)
        .then((data) => {
          this.items = data.data;
          this.rows = data.total_count;
        });
    },
    handleSearch(search) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    async handleCancelBillUsageSuccess() {
      this.$emit("reload");
      await this.pagination(1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}
.header-products {
  font-size: 14px;
  font-weight: bold;
}
.product-panel {
  border-radius: 0 !important;
}
.product-panel.active {
  background-color: var(--secondary-color) !important;
}
.product-panel.spa-disable > div {
  opacity: 0.5;
}
.product-panel.spa-disable .action {
  opacity: 1;
}
.item-products {
  color: #333;
  font-size: 16px;
  border-radius: 8px;
  font-family: Kanit-Medium !important;
  height: 100%;
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
  svg {
    color: #000;
  }
  .btn-product-name {
    color: #707070;
    text-align: left;
    padding: 0;
    svg {
      color: #707070;
      font-weight: bold !important;
    }
  }
  .expire-date {
    color: var(--primary-color) !important;
    font-size: 12px;
  }
  .balance {
    color: var(--primary-color) !important;
    text-align: center;
    font-size: 16px;
  }
  .price {
    font-size: 14px;
    text-align: center;
  }
  .normal-unit-price {
    text-decoration: line-through;
  }
  .action {
    .dropdown-menu.show {
      padding: 0 !important;
      margin-bottom: 0 !important;
      min-width: 100px !important;
      width: 100px !important;
      .dropdown-item {
        padding: 5px !important;
        font-size: 12px !important;
      }
    }
  }
}
.delete-credit {
  background-color: #000 !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px;
  text-align: center;
  svg {
    color: white;
    font-size: 16px;
  }
}
::v-deep .dropdown-icon {
  padding: 0 5px !important;
  svg {
    color: #333 !important;
    font-size: 14px;
  }
}
::v-deep .dropdown-menu.show {
  padding: 0 !important;
  margin-bottom: 0 !important;
  min-width: 100px !important;
  width: 100px !important;
  .dropdown-item {
    padding: 5px !important;
    font-size: 12px !important;
  }
}
::v-deep .text-gray {
  color: #919191 !important;
  .price,
  svg {
    color: #919191 !important;
  }
}
.bg-secondary {
  background-color: var(--secondary-color) !important;
}
</style>
