<template>
  <div class="d-flex">
    <div class="layout-left">
      <div class="p-3">
        <p class="header-text">คอร์สทั้งหมด</p>
        <b-tabs v-model="tabIndex" @input="getDataByTab">
          <b-tab
            :title="`ทั้งหมด (${spaCourseList[0].packageList.length})`"
            class="h-100"
          >
            <PanelCourseList
              :tabIndex="tabIndex"
              :user="user"
              :creditBalance="spaCourseList[0].creditBalance"
              :packageList="spaCourseList[0].packageList"
              :categoryAh2List="categoryAh2List"
              @reload="getDataAllTabs"
            />
          </b-tab>
          <b-tab
            :title="`คอร์สที่ใช้ได้ (${spaCourseList[1].packageList.length})`"
          >
            <PanelCourseList
              :tabIndex="tabIndex"
              :user="user"
              :creditBalance="spaCourseList[1].creditBalance"
              :packageList="spaCourseList[1].packageList"
              :categoryAh2List="categoryAh2List"
              @reload="getDataAllTabs"
            />
          </b-tab>
          <b-tab
            :title="`คอร์สหมดอายุ / คอร์สที่ใช้หมดแล้ว (${spaCourseList[2].packageList.length})`"
          >
            <PanelCourseList
              :tabIndex="tabIndex"
              :user="user"
              :creditBalance="spaCourseList[2].creditBalance"
              :packageList="spaCourseList[2].packageList"
              :categoryAh2List="categoryAh2List"
              @reload="getDataAllTabs"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="layout-right">
      <b-row class="mb-2">
        <b-col>
          <div class="px-3 pt-2">
            <InputText
              v-model="filter.member.search"
              type="text"
              faIcon="search"
              className="mb-2 rounded"
              @onKeypress="handleSearchCustomer"
              @onClickIcon="handleSearch"
              @selectAutoComplete="setUserSelect"
              :optionsAutoComplete="customerList"
              textField="telephone"
              @onEnter="handleSearch"
            />
            <b-row class="align-items-center mb-1">
              <b-col cols="4" class="pr-0"
                ><p class="text-title">ชื่อ-สกุล</p></b-col
              >
              <b-col>
                <InputText
                  :value="`${user.first_name_th} ${user.last_name_th}`"
                  type="text"
                  className="input-border-0"
                  isDisplay
                />
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="4" class="pr-0"
                ><p class="text-title">เบอร์โทรศัพท์</p></b-col
              >
              <b-col>
                <InputText
                  v-model="user.telephone"
                  type="text"
                  name="telephone"
                  className="input-border-0"
                  isDisplay
                />
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="4" class="pr-0"
                ><p class="text-title">หมายเลขสมาชิก</p></b-col
              >
              <b-col>
                <InputText
                  :value="`${user.member_id} ${
                    user.member_level_name
                      ? '(' + user.member_level_name + ')'
                      : ''
                  }`"
                  type="text"
                  name="member_id"
                  className="input-border-0"
                  isDisplay
                />
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="4" class="pr-0"
                ><p class="text-title">คะแนนสะสมสาขา</p></b-col
              >
              <b-col>
                <InputText
                  :value="user.user_guid ? user.point : ''"
                  type="text"
                  name="point"
                  className="input-border-0"
                  isDisplay
                />
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="4" class="pr-0"
                ><p class="text-title">คะแนนสะสมทั้งหมด</p></b-col
              >
              <b-col>
                <InputText
                  :value="user.user_guid ? user.center_point : ''"
                  type="text"
                  name="center_point"
                  className="input-border-0"
                  isDisplay
                />
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-1">
              <b-col cols="4" class="pr-0"
                ><p class="text-title">วงเงินเครดิต</p></b-col
              >
              <b-col>
                <InputText
                  :value="user.user_guid ? user.credit : ''"
                  type="text"
                  name="credit"
                  className="input-border-0"
                  isDisplay
                />
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="d-inline px-3">
        <router-link :to="`/transaction?memberId=${user.member_id}`">
          <b-button
            variant="primary-color"
            class="btn-main-custom mr-1"
            :disabled="!user.user_guid"
          >
            รายการขายย้อนหลัง
          </b-button>
        </router-link>
        <b-button
          variant="primary-color"
          class="btn-main-custom mr-1"
          :disabled="!user.user_guid"
          @click="editMember"
        >
          แก้ไขข้อมูลลูกค้า
        </b-button>
        <b-button
          target="_blank"
          :to="`redeem${user.user_guid ? '?telephone=' + user.telephone : ''}`"
          variant="primary-color"
          class="btn-gift"
        >
          <font-awesome-icon icon="gift" />
        </b-button>
      </div>
      <div></div>
    </div>
    <ModalCustomerList ref="modalCustomerList" @setUserSelect="setUserSelect" />
    <ModalEditMember
      ref="modalEditMember"
      @success="getUserDetail(user.user_guid)"
    />
    <SlideCustomerList ref="SlideCustomerList" @setUserSelect="setUserSelect" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputText from '@/components/inputs/InputText';
import ModalCustomerList from './components/ModalCustomerList';
import ModalEditMember from './components/ModalEditMember';
import PanelCourseList from './components/credit/PanelCourseList.vue';
import SlideCustomerList from '@/views/pages/home/mobile/components/SlideCustomerList';

export default {
  components: {
    InputText,
    ModalCustomerList,
    ModalEditMember,
    PanelCourseList,
    SlideCustomerList
  },
  name: 'Spa',
  computed: {
    ...mapGetters(['isLoading']),
    ...mapGetters({
      mainLoading: 'getMainLoading',
      branchId: 'getBranchId'
    }),
    countTab1() {
      let count = 0;
      if (this.spaCourseList[0].creditBalance) count += 1;

      count += this.spaCourseList[0].packageList.length;

      return count;
    }
  },
  data() {
    return {
      filter: {
        search: '',
        saleId: '',
        invoiceNo: '',
        member: {
          search: '0837060877'
        }
      },
      form: {
        saleId: '',
        invoiceNo: '',
        product: [],
        // promotions: [],
        selectedListPromotionId: [],
        promotionCustom: [],
        promotion: [],
        credit: null,
        user_guid: ''
      },
      credit: {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: null
      },
      user: {
        center_point: 0,
        created_time: '',
        credit: 0,
        first_name_th: '',
        last_name_th: '',
        member_id: '',
        member_level_id: 0,
        member_level_name: '',
        point: 0,
        telephone: '',
        user_guid: '',
        user_id: 0,
        valid_user: 1,
        birthday: '',
        consent_status: ''
      },
      tabIndex: 0,
      alertMsg: '',
      alertText: '',
      customerList: [],
      spaCourseList: {
        0: {
          creditBalance: null,
          packageList: []
        },
        1: {
          creditBalance: null,
          packageList: []
        },
        2: {
          creditBalance: null,
          packageList: []
        }
      },
      categoryAh2List: [],
      isMobile: false
    };
  },
  created() {
    this.getCategoryAh2List();
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768;
      this.$refs.modalCustomerList.hide();
      this.$refs.SlideCustomerList.hide();
    },
    clearPage() {
      window.location.reload();
    },
    async init() {
      this.tabIndex = 0;
      this.alertMsg = '';
      this.alertText = '';

      this.user = {
        center_point: 0,
        created_time: '',
        credit: 0,
        first_name_th: '',
        last_name_th: '',
        member_id: '',
        member_level_id: 0,
        member_level_name: '',
        point: 0,
        telephone: '',
        user_guid: '',
        user_id: 0,
        valid_user: 1,
        birthday: '',
        consent_status: ''
      };
      this.form = {
        saleId: '',
        invoiceNo: '',
        product: [],
        selectedListPromotionId: [],
        promotionCustom: [],
        promotion: [],
        credit: null,
        user_guid: ''
      };
    },
    async editMember() {
      await this.getUserDetail(this.user.user_guid);
      let user = { ...this.user };
      user.firstname = this.user.first_name_th;
      user.lastname = this.user.last_name_th;
      user.firstname_en = this.user.first_name_en;
      user.lastname_en = this.user.last_name_en;
      user.birthday = this.user.birthday;
      user.email = this.user.email.trim();

      this.$refs.modalEditMember.show(user);
    },
    handleSearch() {
      this.customerList = [];

      if (this.isMobile) this.$refs.SlideCustomerList.show(this.filter.search);
      else this.$refs.modalCustomerList.show(this.filter.search);
    },
    async handleSearchCustomer() {
      this.customerList = await this.$refs.modalCustomerList.getCustomers(
        this.filter.member.search
      );
    },
    async setUserSelect(user) {
      //   this.$v.form.$reset();
      await this.init();
      await this.getUserDetail(user.user_guid);
      await this.getDataAllTabs();
    },
    async getDataAllTabs() {
      await this.getDataByTab(0);
      await this.getDataByTab(1);
      await this.getDataByTab(2);
    },
    async getUserDetail(userGuid) {
      await this.axios
        .get(
          `${this.$baseUrl}/customer/user_lookup_byid/${userGuid}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.user = data.detail;
            if (this.user.birthday == '01/01/0001 00:00:00')
              this.user.birthday = null;
            else
              this.user.birthday = this.$moment(
                this.user.birthday,
                'MM/DD/YYYY HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss');
          }
        });
    },
    async getCreditBalance(userGuid, status) {
      let is_expired = 2;
      if (status == 1) is_expired = 0;
      else if (status == 2) is_expired = 1;
      let body = {
        user_guid: userGuid,
        is_expired: is_expired
      };
      await this.axios
        .post(`${this.$baseUrl}/spa/spa_credit_balance`, body)
        .then(async data => {
          if (data.result == 1) {
            this.spaCourseList[status].creditBalance = data.detail;
          }
        });
    },
    async getPackageProduct(userGuid, status) {
      let is_expired = 2;
      if (status == 1) is_expired = 0;
      else if (status == 2) is_expired = 1;
      this.spaCourseList[status].packageList = [];
      let body = {
        user_guid: userGuid,
        is_expired: is_expired
      };
      await this.axios
        .post(`${this.$baseUrl}/spa/spa_package_product`, body)
        .then(async data => {
          if (data.result == 1) {
            let packageList = data.detail.package;
            await packageList.forEach(async (product, i) => {
              let sum = 0;
              await product.product_list.forEach(item => {
                sum += item.usage_count;
              });
              packageList[i].product_usage_count = sum;
            });
            this.spaCourseList[status].packageList = packageList;
          }
        });
    },
    async getDataByTab(tab) {
      let status = tab;
      await this.getCreditBalance(this.user.user_guid, status);
      await this.getPackageProduct(this.user.user_guid, status);
    },
    async getCategoryAh2List() {
      let list = [{ ah_2_id: '', ah_2_name: 'ประเภท' }];
      await this.axios
        .get(`${this.$baseUrl}/product/get_category_ah2`)
        .then(data => {
          this.categoryAh2List = list.concat(data.detail);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.head-total {
  p {
    text-align: center;
    color: var(--primary-color) !important;
    font-size: 14px;
    font-weight: 600;
  }
}
::v-deep .layout-left {
  width: calc(100% - 400px);
  height: calc(100vh - 46px);
}
::v-deep .layout-right {
  width: 400px;
  background: #f7f7f7;
  height: calc(100vh - 46px);
  position: relative;
  p.text-title {
    font-size: 14px;
    font-weight: bold;
  }
  .btn-gift {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px none #f7f7f7;
    background: white;
    text-align: center;
    padding: 8px 0;
    svg {
      color: var(--primary-color) !important;
      font-size: 22px;
    }
  }
  .btn-main-custom {
    border-radius: 20px;
    height: 40px;
    width: calc((100% - 40px - 2.5rem) / 2);
  }
  .tab-discount {
    height: calc(100% - 332px - 52px - 71px - 40px);
    position: relative;
    .wrap-nav-content {
      height: calc(100vh - 575px);
      overflow-y: auto;
      .disabled {
        opacity: 0.7 !important;
      }
    }
    .nav-tabs .nav-link.active {
      border-color: var(--primary-color) !important;
      color: var(--primary-color) !important;
      background: white !important;
    }
    .nav-tabs .nav-link {
      border-bottom: 3px solid #dedede !important;
      background: #dedede !important;
      font-weight: 600;
    }
    .promotion-name {
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      margin-top: 4px;
    }
    .promotion-edit {
      font-size: 12px;
      cursor: pointer;
      margin: 0;
      margin-left: 25px;
      text-decoration: underline;
      padding: 0 !important;
      color: #333 !important;
    }
    .promotion-right {
      font-weight: bold;
      color: var(--primary-color);
      text-align: right;
      .promotion-result {
        font-size: 16px;
      }
      .promotion-point {
        font-size: 10px;
      }
    }
  }
  .btn-submit {
    border-radius: 0px;
    width: 100%;
    height: 40px;
    font-size: 20px;
    line-height: 20px;
    background: var(--primary-color) !important;
    color: white !important;
  }
  .img-coupon {
    cursor: pointer;
    width: 32px;
    margin-left: 10px;
  }
  .panel-coupon {
    .input-custom .has-icon input {
      background: #cbcbcb;
      color: white !important;
    }
  }
}
</style>
