<template>
  <div>
    <b-modal
      v-model="showModal"
      centered
      ref="modal"
      size="lg"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">แก้ไขวันหมดอายุ</div>  </template>
      <div>
        <div class="h-100">
          <div class="m-2 d-flex justify-content-between credit-panel">
            <p>เครดิตสะสม</p>
            <div>
              <span class="total">{{
                credit.total_credit | numeral("0,0.00")
              }}</span>
              บาท
            </div>
          </div>
          <b-row class="p-2">
            <b-col>
              <HeaderPanel title="ประวัติย้อนหลัง" :hasFilter="false" />
              <b-table
                responsive
                striped
                hover
                :fields="credit.fields"
                :items="credit.items"
                :busy="credit.isBusy"
                show-empty
                empty-text="No matching records found"
                class="table-main"
                :no-border-collapse="false"
                sticky-header
              >
                <template v-slot:cell(total_credit)="data">
                  <div>
                    {{ data.item.total_credit | numeral("0,0.00") }}
                  </div>
                </template>
                <template v-slot:cell(credit_balance)="data">
                  <div>
                    {{ data.item.credit_balance | numeral("0,0.00") }}
                  </div>
                </template>
                <template v-slot:cell(created_time)="data">
                  <div>
                    {{
                      new Date(data.item.created_time) | moment($formatDateNew)
                    }}
                  </div>
                </template>
                <template v-slot:cell(expired_date)="data">
                  <div>
                    {{
                      new Date(data.item.expired_date) | moment($formatDateNew)
                    }}
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <font-awesome-icon
                    icon="edit"
                    class="edit"
                    @click="editExpiredDate(data.item)"
                  />
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-25 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ปิด
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalCreditEditExpiredDate ref="modalCreditEditExpiredDate" @success="getCreditData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import ModalCreditEditExpiredDate from "./ModalCreditEditExpiredDate";

export default {
  components: {
    HeaderPanel,
    ModalCreditEditExpiredDate,
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      credit: {
        fields: [
          {
            key: "created_time",
            label: "วันที่ซื้อ",
          },
          {
            key: "total_credit",
            label: "จำนวนเครดิต (บาท)",
          },
          {
            key: "credit_balance",
            label: "คงเหลือ (บาท)",
          },
          {
            key: "expired_date",
            label: "วันที่หมดอายุ",
          },
          {
            key: "action",
            label: "",
          },
        ],
        form: {
          id: 1,
          net_price: 0,
          discount_price: 0,
          total_price: 0,
          expired_date: null,
        },
        filter: {
          search: "",
        },
        total_credit: 0,
        items: [],
        isBusy: false,
        rows: 0,
      },
      user: null
    };
  },
  watch: {
    "credit.form.net_price"(newValue, oldValue) {
      this.credit.form.total_price =
        parseFloat(newValue) + parseFloat(this.credit.form.discount_price);
    },
    "credit.form.discount_price"(newValue, oldValue) {
      this.credit.form.total_price =
        parseFloat(newValue) + parseFloat(this.credit.form.net_price);
    },
  },
  methods: {
    async show(user, item) {
      //  this.credit = item
      this.user = user;
      this.credit.form = {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: this.$moment()
          .add(2, "years")
          .format("YYYY-MM-DDTHH:mm:ss"),
      };
      await this.getCreditData();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getCreditData() {
      await this.axios
        .get(
          `${this.$baseUrl}/product/get_creditbalance/${this.user.user_guid}`
        )
        .then((data) => {
          this.credit.items = data.credit;
          this.credit.rows = this.credit.items.length;
          this.credit.total_credit = data.total_credit;
        });
    },
    editExpiredDate(item) {
      this.$refs.modalCreditEditExpiredDate.show(this.user, item);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
</style>
