<template>
  <div>
    <b-modal
      centered
      v-model="showModal"
      ref="modal"
      size="xl"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">ยืนยันการใช้งานเครดิต</div>  </template>
      <div>
        <div class="h-100">
          <b-row class="px-2">
            <b-col>
              <b-row>
                <b-col
                  ><InputText
                    v-model="form.saleId"
                    textFloat="รหัสพนักงานขาย "
                    className="mb-3"
                    isRequired
                    :isValidate="$v.form.saleId.$error"
                    :v="$v.form.saleId"
                /></b-col>
                <b-col>
                  <InputTextMask
                    textFloat="วันที่ใช้ "
                    placeholder="dd/mm/yyyy hh:mm"
                    :value="form.usageTime"
                    mask="##/##/#### ##:##"
                    masked
                    @onDataChange="changeTime"
                    className="mb-3"
                    isRequired
                    :isValidate="$v.form.usageTime.$error"
                    :v="$v.form.usageTime"
                    :disabled="
                      !(allowTimeChangeService == 'true' && isFullPos == 0)
                    "
                  />
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                class="table-main"
                :no-border-collapse="false"
                sticky-header
              >
                <template v-slot:cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:cell(selling_price)="data">
                  <div>
                    {{ data.item.selling_price | numeral("0,0.00") }}
                  </div>
                </template>
                <template v-slot:cell(quantity)="data">
                  <div>
                    {{ data.item.quantity | numeral("0,0") }}
                  </div>
                </template>
                <template v-slot:cell(action)="data">
                  <b-button variant="link" @click="deleteProduct(data)">
                    ลบ
                  </b-button>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-25 ml-1"
            @click="confirm"
            :disabled="isLoading"
          >
            ตกลง
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import InputText from "@/components/inputs/InputText";
import InputTextMask from "@/components/inputs/InputTextMask";

import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    HeaderPanel,
    InputText,
    InputTextMask,
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      userGuid: "getUserGuid",
      allowTimeChangeService: "getAllowTimeChangeService",
      isFullPos: "getIsFullPos",
    }),
  },
  validations() {
    return {
      form: {
        saleId: {
          required,
        },
        usageTime: {
          required,
          maxValueToday: (value) => {
            let dateTime = this.$moment(value, "DD/MM/YYYY HH:mm")
              .add(-543, "years")
              .unix();
            let now = this.$moment().unix();
            return dateTime <= now;
          },
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      fields: [
        {
          key: "barcode",
          label: "รหัสสินค้า Barcode",
        },
        {
          key: "name",
          label: "ชื่อสินค้า Name",
        },
        {
          key: "selling_price",
          label: "ราคาสินค้า Price",
        },
        {
          key: "quantity",
          label: "จำนวนครั้ง Duration",
        },
      ],
      form: {
        productId: 0,
        userGuid: "",
        branchId: 0,
        saleId: "",
        usertransactionProductSpaId: 0,
        packageCombinationId: 0,
        sellingPrice: 0.0,
        quantity: 0,
        usageTime: null,
        salesList: [],
      },
      filter: {
        page: 1,
        take : 10,
        search: "",
        category: "",
      },
      items: [],
      isBusy: false,
      rows: 0,
      user: false,
      credit: false,
      spaCreditList: [],
      product: null,
      saleList: [],
    };
  },
  methods: {
    async show(user, product) {
      this.user = user;
      this.product = product;
      this.salesList = [];
      this.form = {
        productId: 0,
        userGuid: "",
        branchId: 0,
        saleId: "",
        usertransactionProductSpaId: 0,
        packageCombinationId: 0,
        sellingPrice: 0.0,
        quantity: 0,
        usageTime: this.$moment().add(543, "years").format("DD/MM/YYYY HH:mm"),
        salesList: [],
      };
      await this.getSingleCourseDetail();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    changeTime(val) {
      this.form.usageTime = val;
    },
    async getSingleCourseDetail() {
      var body = {
        user_transaction_product_id: this.product.user_transaction_product_id,
        user_transaction_product_spa_id:
          this.product.user_transaction_product_spa_id,
      };
      await this.axios
        .post(`${this.$baseUrl}/spa/get_spa_single_course_detail`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.items = data.data;
          }
        });
    },
    async onDataChange(val) {
      this.filter.category = val;
      this.getList();
    },
    async checkIsSalesValid() {
      let result = false;
      try {
        this.isLoading = false;
        await this.axios
          .get(
            `${this.$baseUrl}/sales/${this.form.saleId}/branch/${this.branchId}`
          )
          .then(async (data) => {
            if (data.result == 1) {
              this.form.salesList.push(this.form.saleId);
              result = true;
            } else {
              this.warningAlert(data.message || "ไม่พบข้อมูล");
            }
          });
      } catch (error) {
        this.warningAlert(error);
      }
      return result
    },
    async confirm() {
      if (this.form.saleId) {
        let result =  await this.checkIsSalesValid();
        if(!result) return;
      }

      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      let product = this.items[0];
      let body = {
        product_id: product.product_id,
        user_guid: this.user.user_guid,
        branch_id: this.branchId,
        sales_id: this.form.saleId,
        user_transaction_product_spa_id: product.user_transaction_product_spa_id,
        package_combination_id: product.package_combination_id,
        selling_price: product.selling_price,
        quantity: 1,
        usage_time: this.form.usageTime,
        sales_list: this.form.salesList,
      };

      try {
        this.isLoading = false;
        await this.$store.dispatch("setMainLoading", true);
        await this.axios
          .post(`${this.$baseUrl}/spa/use_spa_single_course`, body)
          .then(async (data) => {
            await this.$store.dispatch("setMainLoading", false);
            if (data.result == 1) {
              await this.successAlert(data.message);
              await this.$emit("success");
              await this.hide();
            } else {
              this.warningAlert(data.message);
            }
          });
      } catch (error) {
        await this.$store.dispatch("setMainLoading", false);
        this.warningAlert(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}
.btn-quantity {
  padding: 0;
  svg {
    color: #333 !important;
  }
}
.panel-total {
  font-size: 22px;
  font-family: "Kanit-Medium" !important;
  .title {
    font-size: 14px;
  }
}
::v-deep .btn-link {
  padding: 0 10px;
  color: #333;
  text-decoration: underline;
  cursor: pointer !important;
}
::v-deep .btn-link.delete {
  svg {
    color: #707070 !important;
  }
}
::v-deep .btn-product-name {
  color: #707070 !important;
  text-align: left;
  padding: 0;
  svg {
    color: #707070 !important;
    font-weight: bold !important;
  }
}
::v-deep .is-credit {
  color: #d31145 !important;
}
</style>
