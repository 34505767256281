<template>
  <div>
    <b-modal
      v-model="showModal"
      centered
      ref="modal"
      size="lg"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">สินค้าและบริการสปา</div>  </template>
      <div>
        <div class="h-100">
          <b-row class="p-2">
            <b-col>
              <div class="d-flex justify-content-between credit-panel mb-2">
                <p>วงเงินเครดิตคงเหลือ</p>
                <div>
                  <span class="total">{{
                    credit.remaining_balance | numeral("0,0.00")
                  }}</span>
                  บาท
                </div>
              </div>
              <b-row class="align-items-end mb-3">
                <b-col md="5"
                  ><InputSelect
                    title="ประเภท"
                    isRequired
                    :options="categoryAh2List"
                    v-model="filter.category"
                    @onDataChange="onDataChange"
                    textField="ah_2_name"
                    valueField="ah_2_id"
                    className="mb-0"
                /></b-col>
                <b-col>
                  <InputText
                    v-model="filter.search"
                    type="text"
                    faIcon="search"
                    className=""
                    @onClickIcon="handleSearch"
                    @onEnter="handleSearch"
                    placeholder="ค้นหา"
                  />
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
                class="table-main"
                :no-border-collapse="false"
                sticky-header
              >
                <template v-slot:cell(id)="data">
                  <div>
                    <b-form-checkbox
                      :id="`checkbox-group-${data.item.id}`"
                      v-model="data.item.checked"
                      @input="
                        (val) => handleChecked(val, data.index, data.item)
                      "
                    ></b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <p class="font-weight-bold">
                    {{ data.item.name }}
                  </p>
                  <p>
                    {{ data.item.category_name }}
                  </p>
                </template>
                <template v-slot:cell(price)="data">
                  <div>
                    {{ data.item.price | numeral("0,0.00") }}
                  </div>
                </template>
                <template v-slot:cell(duration)="data">
                  <div
                    class="w-100 d-flex align-items-center justify-content-center"
                  >
                    <b-button
                      variant="quantity"
                      @click="downProductQuantity(data.index)"
                      :disabled="!data.item.checked"
                    >
                      <font-awesome-icon
                        icon="minus-circle"
                        title="down-btn"
                        class="pointer"
                      />
                    </b-button>
                    <p class="mx-2">{{ data.item.quantity }}</p>
                    <b-button
                      variant="quantity"
                      @click="upProductQuantity(data.index)"
                      :disabled="!data.item.checked"
                    >
                      <font-awesome-icon
                        icon="plus-circle"
                        title="up-btn"
                        class="pointer"
                      />
                    </b-button>
                  </div>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-25 ml-1"
            @click="confirm"
            :disabled="isLoading"
          >
            ตกลง
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalComfirmUseCredit
      ref="modalComfirmUseCredit"
      @success="$emit('success')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import InputSelect from "@/components/inputs/InputSelect";
import ModalComfirmUseCredit from "./ModalComfirmUseCredit";
import InputText from "@/components/inputs/InputText";

export default {
  props: {
    categoryAh2List: {
      type: Array,
      default: [],
    },
  },
  components: {
    HeaderPanel,
    InputSelect,
    ModalComfirmUseCredit,
    InputText,
  },
  computed: {
    selectedProductIdList() {
      let value = this.selectedProductList.map((obj) => obj.id);
      return value;
    },
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      fields: [
        {
          key: "id",
          label: "",
        },
        {
          key: "barcode",
          label: "รหัสสินค้า Barcode",
        },
        {
          key: "name",
          label: "ชื่อสินค้า Name",
          tdClass: "text-left",
        },
        {
          key: "price",
          label: "ราคาสินค้า Price",
        },
        {
          key: "duration",
          label: "จำนวนครั้ง Duration",
        },
      ],
      form: {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: null,
      },
      filter: {
        page: 1,
        take : 10,
        search: "",
        category: "",
      },
      items: [],
      isBusy: false,
      rows: 0,
      selected: [],
      user: false,
      credit: false,
      selectedProductList: [],
    };
  },
  methods: {
    async show(user, credit) {
      this.user = user;
      this.credit = credit;
      this.selectedProductList = [];

      await this.getList();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getList() {
      var body = {
        skip: 0,
        take: -1,
        search: this.filter.search,
        category: this.filter.category,
      };
      await this.axios
        .post(`${this.$baseUrl}/spa/get_spa_product_list`, body)
        .then(async (data) => {
          this.items = await data.data.map((obj) => {
            if (this.selectedProductIdList.includes(obj.id)) {
              let filtered = this.selectedProductList.filter(
                (product) => product.id === obj.id
              );
              if (filtered.length) {
                return filtered[0];
              }else{
              return { ...obj, checked: false, quantity: 0 };
            }
            }else{
              return { ...obj, checked: false, quantity: 0 };
            }
          });
          this.rows = this.items.length;
        });
    },
    handleSearch() {
      this.getList();
    },
    async onDataChange(val) {
      this.filter.category = val;
      this.getList();
    },
    async handleChecked(val, index, item) {
      if (!val) {
        let filtered = await this.selectedProductList.filter(
          (product) => product.id != item.id
        );
        this.selectedProductList = filtered;
        this.items[index].quantity = 0;
      } else {
        await this.selectedProductList.push(item);
      }
    },
    async validateConfirm() {
      let checked = this.selectedProductList.filter((item) => item.checked);
      if (!checked.length) {
        this.warningAlert("กรุณาเลือกสินค้า");
        return false;
      }
      let qty = checked.filter((item) => item.checked && !item.quantity);
      if (qty.length) {
        this.warningAlert("กรุณาระบุจำนวนครั้งที่ต้องการใช้สินค้า");
        return false;
      }

      let sum = 0;
      await checked.forEach((item) => {
        sum += parseInt(item.price) * parseInt(item.quantity);
      });

      if (sum > parseInt(this.credit.remaining_balance)) {
        this.warningAlert("จำนวนเครดิตไม่พอ");
        return false;
      }

      return checked;
    },
    async confirm() {
      let productSelected = await this.validateConfirm();
      if (productSelected) {
        productSelected = await productSelected.map((product) => {
          return {
            product_id: product.id,
            name: product.name,
            price: product.price,
            barcode: product.barcode,
            quantity: product.quantity,
          };
        });
        this.$refs.modalComfirmUseCredit.show(
          this.user,
          this.credit,
          productSelected
        );
        this.hide();
      }
    },
    async downProductQuantity(index) {
      if (this.items[index].quantity >= 1) {
        this.items[index].quantity -= 1;
      }
    },
    async upProductQuantity(index) {
      this.items[index].quantity += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}
.btn-quantity {
  padding: 0;
  svg {
    color: #333 !important;
  }
}
</style>
