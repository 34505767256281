<template>
  <div>
    <b-modal
      centered
      v-model="showModal"
      ref="modal"
      size="lg"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title"> Package </div> </template>
      <div>
        <div class="h-100 panel-package">
          <b-row class="px-3">
            <b-col>
              <b-row v-for="(item, index) in items" :key="index" :class="[]">
                <b-col cols="12" class="package-list-box">
                  {{ item.product_name }}
                </b-col>
                <b-col cols="12">
                  <b-row class="head-table my-2">
                    <b-col> รายการ </b-col>
                    <b-col md="2" class="text-center"> ราคา </b-col>
                    <b-col md="2" class="text-center"> จำนวนครั้ง </b-col>
                  </b-row>
                  <b-row class="body-table py-2  align-items-center">
                    <b-col>
                      <b>Credit Balance</b><br />
                      <p>คงเหลือ {{ remainingBalance | numeral("0,0.00") }}</p>
                    </b-col>
                    <b-col md="2" class="text-center">
                      <span><b> {{ creditBalance | numeral("0,0.00") }}</b></span>
                    </b-col>
                    <b-col md="2"> </b-col>
                  </b-row>
                  <template v-if="item.product_list.length != 0">
                    <b-row
                      v-for="(product, index2) in item.product_list"
                      :key="index2"
                      class="panel-product-list my-3"
                    >
                      <b-col>
                        <b-button
                          v-b-toggle="'collapse-package-' + item.product_id"
                          variant="product-name"
                          class="pointer"
                        >
                          <font-awesome-icon
                            icon="chevron-right"
                            class="when-closed"
                          />
                          <font-awesome-icon
                            icon="chevron-down"
                            class="when-open"
                          />
                          {{ product.name }}
                        </b-button>
                      </b-col>
                      <b-col>
                        <div class="col-xs-6 text-right">
                          จำนวนครั้งที่ใช้ได้ (<span>{{
                            product.quantity - product.usage_count
                          }}</span
                          >/{{ product.quantity }})
                        </div>
                      </b-col>
                      <b-col cols="12" class="pb-3">
                        <b-collapse
                          :id="`collapse-package-${item.product_id}`"
                          :class="['product-panel align-items-center mx-0']"
                        >
                          <b-row
                            v-for="(
                              productDetail, index3
                            ) in product.product_detail"
                            :key="index3"
                            class="my-1"
                          >
                            <b-col class="price text-left pr-0">
                              <div class="d-flex">
                                <b-form-checkbox
                                  :id="`checkbox-group-d-${productDetail.id}`"
                                  v-model="productDetail.checked"
                                  @input="
                                    (val) =>
                                      handleChecked(val, index3, productDetail)
                                  "
                                ></b-form-checkbox>
                                {{ productDetail.name }}
                              </div>
                            </b-col>
                            <b-col md="2" class="price text-center">
                              <p>
                                {{ productDetail.price | numeral("0,0") }}
                              </p>
                            </b-col>
                            <b-col md="2" class="price">
                              <div
                                class="w-100 d-flex align-items-center justify-content-center"
                              >
                                <b-button
                                  variant="quantity"
                                  @click="downProductQuantity(index3)"
                                  :disabled="!productDetail.checked"
                                >
                                  <font-awesome-icon
                                    icon="minus-circle"
                                    title="down-btn"
                                    class="pointer"
                                  />
                                </b-button>
                                <p class="mx-2">{{ productDetail.quantity }}</p>
                                <b-button
                                  variant="quantity"
                                  @click="upProductQuantity(index3)"
                                  :disabled="!productDetail.checked"
                                >
                                  <font-awesome-icon
                                    icon="plus-circle"
                                    title="up-btn"
                                    class="pointer"
                                  />
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                        </b-collapse>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <template #modal-footer="{}">
        <div class="d-flex justify-content-center w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-25 ml-1"
            @click="confirm"
            :disabled="isLoading"
          >
            ตกลง
          </b-button>
        </div>
      </template>
      <pre>{{ items }}</pre>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPanel from "@/components/HeaderPanel";
import InputText from "@/components/inputs/InputText";
import InputTextMask from "@/components/inputs/InputTextMask";

import { required, minValue, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    HeaderPanel,
    InputText,
    InputTextMask,
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
      userGuid: "getUserGuid",
      allowTimeChangeService: "getAllowTimeChangeService",
      isFullPos: "getIsFullPos",
    }),
  },
  validations() {
    return {
      form: {
        saleId: {
          required,
        },
        usageTime: {
          required,
          maxValueToday: (value) => {
            let dateTime = this.$moment(value, "DD/MM/YYYY HH:mm")
              .add(-543, "years")
              .unix();
            let now = this.$moment().unix();
            return dateTime <= now;
          },
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      showModal: true,
      fields: [
        {
          key: "barcode",
          label: "รหัสสินค้า Barcode",
        },
        {
          key: "name",
          label: "ชื่อสินค้า Name",
        },
        {
          key: "selling_price",
          label: "ราคาสินค้า Price",
        },
        {
          key: "quantity",
          label: "จำนวนครั้ง Duration",
        },
      ],
      form: {
        productId: 0,
        userGuid: "",
        branchId: 0,
        saleId: "",
        usertransactionProductSpaId: 0,
        packageCombinationId: 0,
        sellingPrice: 0.0,
        quantity: 0,
        usageTime: null,
        salesList: [],
      },
      filter: {
        page: 1,
        take : 10,
        search: "",
        category: "",
      },
      items: [],
      isBusy: false,
      rows: 0,
      user: false,
      credit: false,
      spaCreditList: [],
      product: null,
      saleList: [],
      creditBalance: 0,
      remainingBalance: 0,
    };
  },
  methods: {
    async show(user, product) {
      this.user = user;
      this.product = product;
      this.salesList = [];
      this.form = {
        productId: 0,
        userGuid: "",
        branchId: 0,
        saleId: "",
        usertransactionProductSpaId: 0,
        packageCombinationId: 0,
        sellingPrice: 0.0,
        quantity: 0,
        usageTime: this.$moment().add(543, "years").format("DD/MM/YYYY HH:mm"),
        salesList: [],
      };
      await this.getSpaProductDetail();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    changeTime(val) {
      this.form.usageTime = val;
    },
    async getSpaProductDetail() {
      var body = {
        user_guid: this.user.user_guid,
        product_id: this.product.product_id,
        user_transaction_product_id: this.product.user_transaction_product_id,
      };
      await this.axios
        .post(`${this.$baseUrl}/spa/get_spa_product_detail`, body)
        .then(async (data) => {
          if (data.result == 1) {
            data.data = await data.data.map((item) => {
              item.product_list = item.product_list.map((product) => {
                product.product_detail = product.product_detail.map(
                  (product_detail) => {
                    return { ...product_detail, checked: false, quantity: 0 };
                  }
                );
                return product;
              });

              return item;
            });

            this.items = data.data;
            this.creditBalance = data.credit_balance;
            this.remainingBalance = data.remaining_balance;
          }
        });
    },
    async onDataChange(val) {
      this.filter.category = val;
      this.getList();
    },
    async checkIsSalesValid() {
      let result = false;
      try {
        this.isLoading = false;
        await this.axios
          .get(
            `${this.$baseUrl}/sales/${this.form.saleId}/branch/${this.branchId}`
          )
          .then(async (data) => {
            if (data.result == 1) {
              this.form.salesList.push(this.form.saleId);
              result = true;
            } else {
              this.warningAlert(data.message || "ไม่พบข้อมูล");
            }
          });
      } catch (error) {
        this.warningAlert(error);
      }
      return result;
    },
    async confirm() {
      if (this.form.saleId) {
        let result = await this.checkIsSalesValid();
        if (!result) return;
      }

      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      let product = this.items[0];
      let body = {
        product_id: product.product_id,
        user_guid: this.user.user_guid,
        branch_id: this.branchId,
        sales_id: this.form.saleId,
        user_transaction_product_spa_id:
          product.user_transaction_product_spa_id,
        package_combination_id: product.package_combination_id,
        selling_price: product.selling_price,
        quantity: 1,
        usage_time: this.form.usageTime,
        sales_list: this.form.salesList,
      };

      try {
        this.isLoading = false;
        await this.$store.dispatch("setMainLoading", true);
        await this.axios
          .post(`${this.$baseUrl}/spa/use_spa_single_course`, body)
          .then(async (data) => {
            await this.$store.dispatch("setMainLoading", false);
            if (data.result == 1) {
              await this.successAlert(data.message);
              await this.$emit("success");
              await this.hide();
            } else {
              this.warningAlert(data.message);
            }
          });
      } catch (error) {
        await this.$store.dispatch("setMainLoading", false);
        this.warningAlert(error);
      }
    },
    handleChecked() {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
.fa-icon-custom {
  width: 32px;
  height: 32px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px none #fff;
  background-color: var(--primary-color) !important;
  padding: 5px 7px;
}
.fa-icon-custom svg {
  color: white !important;
  font-size: 18px;
}
.btn-quantity {
  padding: 0;
  svg {
    color: #333 !important;
  }
}
.panel-total {
  font-size: 22px;
  font-family: "Kanit-Medium" !important;
  .title {
    font-size: 14px;
  }
}
::v-deep .btn-link {
  padding: 0 10px;
  color: #333;
  text-decoration: underline;
  cursor: pointer !important;
}
::v-deep .btn-link.delete {
  svg {
    color: #707070 !important;
  }
}
::v-deep .btn-product-name {
  color: #333 !important;
  text-align: left;
  padding: 0;
  svg {
    color: #333 !important;
    font-weight: bold !important;
  }
}
::v-deep .is-credit {
  color: #d31145 !important;
}
.panel-package {
  font-size: 14px;
  .package-list-box {
    padding: 10px;
    color: #fff;
    background-color: var(--primary-color) !important;
  }
  .head-table {
    font-weight: bold;
  }
  .body-table {
    background: #dcdcdc;
  }
  .panel-product-list {
    background-color: var(--secondary-color) !important;
  }
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
  .product-panel {
    font-weight: bold;
  }
}
</style>
