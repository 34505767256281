<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="xl"
      hide-footer
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div>ค้นหาลูกค้า</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <InputText
          v-model="filter.search"
          textFloat="ค้นหา"
          type="text"
          name="search_customer"
          faIcon="search"
          className="mb-2 w-50"
          @onEnter="handleSearch"
          @onClickIcon="handleSearch"
        />
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
            >
              <template v-slot:cell(select)="data">
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hover"
                  @click="selectRow(data.item)"
                  :disabled="isBusy"
                >
                  เลือก
                </b-button>
              </template>
              <template v-slot:cell(member_id)="data">
                <p>{{ data.item.member_id || "-" }}</p>
              </template>
              <template v-slot:cell(first_name_th)="data">
                <p>{{ data.item.first_name_th || "-" }}</p>
              </template>
              <template v-slot:cell(last_name_th)="data">
                <p>{{ data.item.last_name_th || "-" }}</p>
              </template>
              <template v-slot:cell(point)="data">
                <div>{{ data.item.point | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(center_point)="data">
                <div>{{ data.item.center_point | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div>
                  {{
                    new Date(data.item.created_time) | moment($formatDateNew)
                  }}
                </div>
                <div class="time-color">
                  {{
                    new Date(data.item.created_time) | moment($formatTimeNew)
                  }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    src="@/assets/images/icons/note.png"
                    alt="transfer"
                    class="action-img pointer"
                    width="20"
                    center
                    @click="$refs.modalNote.show(data.item)"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
    
        <Pagination
          @handleChangeTake="handleChangeTake"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </b-modal>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import OtherLoading from "@/components/loading/OtherLoading";
import Pagination from "@/components/Pagination";
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";
import ModalNote from "@/components/customer/ModalNote";

export default {
  components: {
    InputTextArea,
    OtherLoading,
    Pagination,
    InputText,
    ModalNote,
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      filter: {
        search: "",
        datetime: "",
        branchId: "",
        page: 1,
        take : 10,
      },
      fields: [
      {
          key: "select",
          label: "",
        },
        {
          key: "first_name_th",
          label: "ชื่อ",
          tdClass: "text-left min-w-100",
        },
        {
          key: "last_name_th",
          label: "นามสกุล",
          tdClass: "text-left min-w-100",
        },
        {
          key: "point",
          label: "คะแนนสาขา",
          tdClass: "min-w-150",
        },
        {
          key: "center_point",
          label: "คะแนนสะสม",
          tdClass: "min-w-150",
        },
        {
          key: "telephone",
          label: "เบอร์โทร",
        },
        {
          key: "member_id",
          label: "Member ID",
        },
        {
          key: "member_id",
          label: "Tier",
        },
        {
          key: "created_time",
          label: "วันที่สมัคร",
        },
        {
          key: "action",
          label: "",
          tdClass: "text-center",
        },
      ],
      now: null,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      isBusy: false,
      rows: 0,
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.branch_id = this.branchId;
      payload.datetime = this.dateTimeFix || this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      this.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/customer/searchuser`, payload)
        .then( async (data) => {
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            // if(this.rows == 1) {
            //   await this.setUserSelect(this.items[0]);
            //   await this.hide();
            // }
            this.isLoading = false;
          }
        });

      this.isBusy = false;
    },
    async getCustomers(search) {
      this.filter.search = search;
      await this.pagination(1);
      return this.items;
    },
    async show(search) {
      this.filter.search = search;
      this.showModal = true;
      await this.pagination(1);
      if(this.rows == 1) {
        await this.setUserSelect(this.items[0]);
        await this.hide();
      }
    },
    hide() {
      this.showModal = false;
    },
    async getNote(userGuid) {
      this.isBusy = true;
      await this.axios
        .get(`${this.$baseUrl}/redeem/get_note/${userGuid}`)
        .then((data) => {
          if (data.result == 1) {
            this.form.note = data.note || "";
          }
        });
      this.isBusy = false;
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getList();
    },
    clearFilter() {
      this.filter.page = 1;
      this.filter.take = 10;
      this.getList();
    },
    handleSearch(search) {
      this.filter.search = search;
      this.pagination(1);
    },
    async selectRow(item){
      await this.setUserSelect(item);
      await this.hide();
    },
    setUserSelect(item) {
      this.$emit("setUserSelect", item);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.table-main{
  min-height: 360px;
}
</style>
