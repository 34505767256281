<template>
  <div>
    <b-modal
      v-model="showModal"
      centered
      ref="modal"
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header> <div class="modal-title">แก้ไขวันหมดอายุ</div>  </template>
      <InputText
        v-model="form.update_by"
        textFloat="Manager ID:"
        className="mb-3"
        isRequired
        :isValidate="$v.form.update_by.$error"
        :v="$v.form.update_by"
      />
      <b-row class="header-title">
        <b-col class="text-center" md="3"> วันที่ซื้อ </b-col>
        <b-col class="text-left"> วันที่หมดอายุ </b-col>
      </b-row>
      <b-row class="text-center content-body mt-2">
        <b-col
          md="3"
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <div>
            {{
              $moment(form.created_time)
                .add(543, "years")
                .format($formatDateNew)
            }}
          </div>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-center">
          <InputDatePickerFilter
            name="changeTime"
            ref="changeTime"
            v-model="expired_date"
            @input="changeTime"
            :isValidate="$v.expired_date.$error"
            :v="$v.expired_date"
            className="w-100"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import { mapGetters } from "vuex";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";

export default {
  components: {
    InputDatePickerFilter,
    InputText,
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        id: 0,
        update_by: "",
        expired_date: null,
        user_guid: "",
      },
      expired_date: null,
      user: null
    };
  },
  validations() {
    return {
      expired_date: {
        required,
      },
      form: {
        update_by: {
          required,
        },
      },
    };
  },
  methods: {
    async show(user, item) {
      this.user = user;
      this.form = { ...item };
      (this.expired_date = this.$moment(this.form.expire_date_time).format(
        "YYYY-MM-DDTHH:mm:ss"
      )),
        this.$v.$reset();

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    changeTime(val) {
      this.form.changeTime = val;
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.isLoading = true;
      let body = {
        id: this.form.id,
        expired_date: this.$moment(this.expired_date)
          .add(543, "years")
          .format("DD/MM/YYYY"),
        update_by: this.form.update_by,
        user_guid: this.user.user_guid,
      };
      try {
        this.isLoading = false;
        await this.axios
          .post(`${this.$baseUrl}/spa/update_credit_expire_date`, body)
          .then(async (data) => {
            if (data.result == 1) {
              await this.successAlert();
              await this.$emit("success");
              await this.hide();
            } else {
              this.warningAlert(data.message);
            }
          });
      } catch (error) {
        this.warningAlert(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.header-title {
  font-size: 14px;
  font-weight: bold;
}
.content-body {
  font-size: 14px;
}
</style>
